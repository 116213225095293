import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import "./css/bootstrap.css";
import "./headers.css";
import image1 from "./img/logo.png";
import { Toast } from "./Toast/Toast";
import { usePostAPIJson } from "../Custom-Hook/usePostAPIJson";
import CustomModal from "../Modal/CustomModal";
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;

const Footer = () => {
  let history = useHistory();
  const [isModalVisible, setModalVisible] = useState(false);
  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);

  let getServiceCategoryUrl = `${baseUrl}/getServiceCategoryByUser`;
  const { data: serviceCategory, postData: getServiceCategory } =
    usePostAPIJson(getServiceCategoryUrl);

  let getSpecialityUrl = `${baseUrl}/getSpecialityByUser`;
  const { data: speciality, postData: getSpeciality } =
    usePostAPIJson(getSpecialityUrl);

  let ManageUrl = `${baseUrl}/get-manageUrls`;
  const { data: manageUrlsData, postData: getManageUrlandName } =
    usePostAPIJson(ManageUrl);
  // console.log(manageUrlsData);
  useEffect(() => {
    getServiceCategory();
    getSpeciality();
    getManageUrlandName();
  }, []);
  const redirecttohome = () => {
    history.push("/");
  };

  // Example time slots

  const [toasts, setToasts] = useState([]);

  const addToast = (message, color) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { id: Date.now(), message, color },
    ]);
  };

  const handleToastClose = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <footer>
      <div class="footer">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <h3
                class="a1"
                style={{ "font-size": "2.4rem", "padding-bottom": "0" }}
              >
                I'd Like to
              </h3>
              <h4 class="a5">
                Book Appointment<span>+91 920 6008 400</span>
              </h4>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="site-list1">
                <Link to="/contactUs">Contact Us</Link>
                {/* <a href="#">Pay a Bill</a> */}
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="site-list1">
                <a href={manageUrlsData?.message?.FooterMid1url ?? "#"}>
                  {manageUrlsData?.message?.FooterMid1Name ?? "#"}
                </a>
                <a href={manageUrlsData?.message?.FooterMid2url ?? "#"}>
                  {manageUrlsData?.message?.FooterMid2Name ?? "#"}
                </a>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="site-list1">
                <a href={manageUrlsData?.message?.FooterRight1url ?? "#"}>
                  {manageUrlsData?.message?.FooterRight1Name ?? "#"}
                </a>
                <a href={manageUrlsData?.message?.FooterRight2url ?? "#"}>
                  {manageUrlsData?.message?.FooterRight2Name ?? "#"}
                </a>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-lg-3">
              <img
                class="footer-logo"
                onClick={redirecttohome}
                src={image1}
                alt="Vibrant Hospital"
              />
              <ul class="footer-contact">
                <li>
                  <span class="icon">
                    <img
                      width="30"
                      height="30"
                      loading="lazy"
                      alt="Vibrant Hospital Address"
                      src="https://img.icons8.com/3d-fluency/30/marker.png"
                    />
                  </span>
                  Plot No: 1, Sector 100, Dwarka Expressway, Huda W.T.P., Opp.
                  Hanuman Mandir, Gurugram- 122001
                </li>
                <li>
                  <span class="icon">
                    <img
                      width="30"
                      height="30"
                      loading="lazy"
                      alt="Vibrant Hospital Email Address"
                      src="https://img.icons8.com/3d-fluency/30/filled-message.png"
                    />
                  </span>
                  <a href="mailto:reception@vibranthospital.com">
                    reception@vibranthospital.com
                  </a>
                </li>
                <li>
                  <span class="icon">
                    <img
                      width="30"
                      height="30"
                      loading="lazy"
                      alt="Vibrant Hospital Contact Number"
                      src="https://img.icons8.com/3d-fluency/30/phone.png"
                    />
                  </span>
                  <a href="tel:+919206008400">+91 920 6008 400</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-9">
              <div class="row">
                <div class="col-6 col-md-6 col-lg-3">
                  <div class="site-list2">
                    <h6>Quick Links</h6>
                    <Link to="/">Home</Link>
                    <Link to="/about">About Us</Link>
                    <Link to="/news">News</Link>
                    <Link to="/career">Career</Link>
                    <Link to="/contactUs">Contact Us</Link>
                  </div>
                </div>
                <div class="col-6 col-md-6 col-lg-3">
                  <div class="site-list2">
                    <h6>Services</h6>
                    {serviceCategory?.data?.length > 0
                      ? serviceCategory?.data
                          ?.slice(0, 6)
                          .map((item, index) => {
                            let give = decodeURIComponent(item.Name);
                            const formattedUrl = give
                              .replace(/\s+/g, "-")
                              .toLowerCase();
                            return (
                              <Link
                                to={{
                                  pathname: `/service/${item.url}`,
                                  state: { item },
                                }}
                                key={index}
                              >
                                {item.Name}
                              </Link>
                            );
                          })
                      : null}
                  </div>
                </div>

                <div class="col-6 col-md-6 col-lg-3">
                  <div class="site-list2">
                    <h6>Specialities</h6>
                    {speciality?.data?.length > 0
                      ? speciality?.data?.slice(0, 6).map((item, index) => {
                          let give = decodeURIComponent(item.url);
                          const formattedUrl = give
                            .replace(/\s+/g, "-")
                            .toLowerCase();
                          return (
                            <Link
                              to={`/specialitydetail/${formattedUrl}`}
                              key={index}
                            >
                              {item.Name}
                            </Link>
                          );
                        })
                      : null}
                  </div>
                </div>

                <div class="col-6 col-md-6 col-lg-3">
                  <div class="site-list2">
                    <h6>Other</h6>
                    <Link to="/bloglist">Blogs</Link>
                    <Link to="/Privacy">Privacy Policy </Link>
                    <Link to="/Terms">Terms & Condition</Link>

                    <div className="d-flex gap-3">
                      <a
                        href="https://www.facebook.com/VibrantHospitalGurugram/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="bi bi-facebook fs-3 text-primary"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/vibrant_hospital/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="bi bi-instagram fs-3 text-danger"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ "margin-bottom": "1rem" }} />
        <p class="text-center">
          &copy; Vibrant Hospital | All Rights Reserved.
        </p>
        <p style={{ textAlign: "right", fontSize: ".6rem" }}>
          Powered by{" "}
          <a
            style={{
              textDecoration: "none",
              color: "#ff0901",
            }}
            href="https://studiokrew.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            StudioKrew
          </a>
        </p>
      </div>
      <div className="container">
        <div className="toast-container position-fixed bottom-0 end-0 p-3">
          {toasts.map((toast) => (
            <Toast
              key={toast.id}
              message={toast.message}
              color={toast.color}
              onClose={() => handleToastClose(toast.id)}
            />
          ))}
        </div>
      </div>
      <CustomModal isOpen={isModalVisible} onRequestClose={hideModal} />
    </footer>
  );
};

export default Footer;
