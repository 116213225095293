import React, { useState, useEffect } from "react";
import "./css/bootstrap.css";
import image2 from "./img/breadcrumb-bg.png";
import "./headers.css";
import Header from "./header.js";
import Footer from "./footer.js";
import axios from "axios";
import { Link } from "react-router-dom";
 import MetaTags from "./MetaTags/MetaTags.jsx";
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;

function ContactUs() {
  const [Api, setApi] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    callApi1();
  }, []);

  const callApi1 = () => {
    axios.post(`${baseUrl}/getcontactbyuser`).then((res) => {
      setApi(res.data.data);
    });
  };

  const currentURL = window.location.href;
  return (
    <>
      <div>
        <MetaTags
          title={Api.metaTitle}
          metaDescription={Api.metaDescription}
          metaKeyword={Api.metaKeyword}
          currentURL={currentURL ? currentURL : null}
        ></MetaTags>
      </div>
      <Header />

      <div
        class="page-bg-title"
        style={{ "background-image": `url(${image2})` }}
      >
        <div class="container">
          <h1>Contact Us</h1>
        </div>
      </div>

      <div class="b1">
        <div class="container">
          <div aria-label="breadcrumb">
            <nav
              style={{
                "--bs-breadcrumb-divider": "url(&#34;data:image/svg+xml,%3Csvg",
                xmlns: "http://www.w3.org/2000/svg",
              }}
              aria-label="breadcrumb"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link> / Contact Us
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                
                </li>
              </ol>
            </nav>
          </div>
          <div class="col-lg-9 col-md-10">
            <p class="a2">If you have any queries, reach out to us.</p>
          </div>
        </div>
      </div>

      <div class="bg1">
        <div class="container">
          <div class="row" style={{ "flex-wrap": "nowrap" }}>
            <div class="col-sm-6 col-lg-3">
              <div class="speciality_tab4">
                <div class="image">
                  <img
                    alt=""
                    src="https://img.icons8.com/ios/50/phone--v1.png"
                    width="40"
                    height="40"
                  />
                </div>
                <div class="title">Contact No</div>
                <a href="tel:+919206008400">{Api.contactcno}</a>
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="speciality_tab4">
                <div class="image">
                  <img
                    src="https://img.icons8.com/ios/50/siren.png"
                    alt="siren"
                    width="40"
                    height="40"
                  />
                </div>
                <div class="title">Emergency No</div>
                <a href="tel:+919206008400">{Api.emergencyno}</a>
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="speciality_tab4">
                <div class="image">
                  <img
                    alt=""
                    src="https://img.icons8.com/ios/50/certificate--v1.png"
                    width="40"
                    height="40"
                  />
                </div>
                <div class="title">TPA No</div>
                <a href="tel:+919206008400">{Api.tpano}</a>
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="speciality_tab4">
                <div class="image">
                  <img
                    alt=""
                    src="https://img.icons8.com/external-prettycons-lineal-prettycons/50/external-ambulance-medical-prettycons-lineal-prettycons.png"
                    width="40"
                    height="40"
                  />
                </div>
                <div class="title">Ambulance No</div>
                <a href="tel:+919206008400">{Api.ambulanceno}</a>
              </div>
            </div>
          </div>
          <div class="row" style={{ "margin-top": "1.4rem" }}>
            <div class="col-sm-12 col-lg-4">
              <div class="speciality_tab4">
                <div class="image">
                  <img
                    alt=""
                    src="https://img.icons8.com/ios/50/mail.png"
                    width="40"
                    height="40"
                  />
                </div>
                <div class="title">Email</div>
                <a href="mail:vibranthospitalgurgaon@gmail.com">{Api.email}</a>
              </div>
            </div>

            <div class="col-sm-12 col-lg-4">
              <div class="speciality_tab4">
                <div class="image">
                  <img
                    alt=""
                    src="https://img.icons8.com/ios/50/address--v1.png"
                    width="40"
                    height="40"
                  />
                </div>
                <div class="title">Address</div>
                <p>{Api.address}</p>
              </div>
            </div>

            <div class="col-sm-12 col-lg-4">
              <div class="speciality_tab4">
                <div class="image">
                  <img
                    alt=""
                    src="https://img.icons8.com/carbon-copy/100/24-circle.png"
                    width="40"
                    height="40"
                  />
                </div>
                <div class="title">Opening Hours</div>
                <p>{Api.openinghours}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg1">
        <div class="container">
          <div class="row">
            <div class="col-lg-10">
              <h2 class="a5">Reach Us</h2>
              <form class="row g-3">
                <div class="col-md-6">
                  <label for="inputName" class="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputName"
                    placeholder="Name"
                  />
                </div>
                <div class="col-md-6">
                  <label for="inputEmail" class="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="inputEmail"
                    placeholder="Email"
                  />
                </div>
                <div class="col-12">
                  <label for="inputContactNumber" class="form-label">
                    Contact Number
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputContactNumber"
                    placeholder="Contact Number"
                  />
                </div>
                <div class="col-12">
                  <label for="exampleMessage" class="form-label">
                    Message
                  </label>
                  <textarea
                    class="form-control"
                    id="exampleMessage"
                    rows="3"
                  ></textarea>
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                    />
                    <label class="form-check-label" for="gridCheck">
                      By selecting, I agree to Terms & Condition and Privacy
                      Policy.
                    </label>
                  </div>
                </div>
                <div class="col-12">
                  <button type="submit" class="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <script src="js/bootstrap.bundle.min.js"></script>
    </>
  );
}

export default ContactUs;
