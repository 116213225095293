import Header from "../header.js";
import Footer from "../footer.js";
import React, { useState, useEffect } from "react";
import image1 from "../img/breadcrumb-bg.png";

import axios from "axios";
import "../css/bootstrap.css";
import "../headers.css";
import OwlCarousel from "react-owl-carousel";
import "../owlcarousel/assets/owl.carousel.css";
import "../owlcarousel/assets//owl.theme.default.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import VHDetailSection from "./AboutUsSection/VHDetailSection.jsx";
import MissionAndVission from "./AboutUsSection/MissionAndVission.jsx";
import SecondSection from "./AboutUsSection/SecondSection.jsx";
import CrauselCard from "./AboutUsSection/CrauselCard.jsx";
import MetaTags from "../MetaTags/MetaTags.jsx";
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;
function AboutUs() {
  const [doctordetail, setdoctordetail] = useState([]);
  const [Api, setApi] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    callApi1();
    callApi();
  }, []);

  const callApi = () => {
    axios.post(`${baseUrl}/getaboutusbyuser`).then((res) => {
      setApi(res.data.data);
    });
  };

  const callApi1 = () => {
    let data = { doctors: undefined };

    axios.post(`${baseUrl}/getDoctorlistforhomepage`, data).then((res) => {
      setdoctordetail(res.data.data);
    });
  };

  const options = {
    loop: false,
    center: true,
    responsiveClass: true,
    nav: true,
    items: 0,
    startPosition: 1,
    margin: 20,
    autoplay: false,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1170: {
        items: 3,
        nav: true,
      },
    },
  };

  let tb_data4 = Api
    ? Api.points.map((item, index) => {
        return (
          <ul class="list-unstyled" key={index}>
            <li class="featured_pointers1">
              <span></span> {item}
            </li>
          </ul>
        );
      })
    : null;

  const currentURL = window.location.href;

  return (
    <>
      <div>
        <MetaTags
          title={Api?.metaTitle ?? ""}
          metaDescription={Api?.metaDescription ?? ""}
          metaKeyword={Api?.metaKeyword ?? ""}
          currentURL={currentURL ?? ""}
        ></MetaTags>
      </div>
      <Header />
      <div
        class="page-bg-title"
        style={{ "background-image": `url(${image1})` }}
      >
        <div class="container">
          <h1>About Us</h1>
        </div>
      </div>

      <div class="b1">
        <div class="container">
          <div aria-label="breadcrumb">
            <nav
              style={{
                "--bs-breadcrumb-divider":
                  "url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);",
              }}
              aria-label="breadcrumb"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div class="bg1">
        <VHDetailSection Api={Api}></VHDetailSection>
      </div>

      <div class="bg1">
        <SecondSection Api={Api} tb_data4={tb_data4}></SecondSection>
      </div>

      <div class="bg1">
        <MissionAndVission Api={Api}></MissionAndVission>
      </div>

      <div class="bg1">
        <div class="container">
          <div class="row">
            <h3 class="a6">Our Doctors</h3>
            {/* <!--Doctor TAB--> */}
            <div class="row">
              {doctordetail.length > 1 ? (
                <OwlCarousel
                  id="customers-testimonials1"
                  className="owl-theme"
                  {...options}
                >
                  {doctordetail.length > 1
                    ? doctordetail.map((item, index) => {
                        return (
                          <CrauselCard index={index} item={item}></CrauselCard>
                        );
                      })
                    : null}
                </OwlCarousel>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AboutUs;
