import "../css/bootstrap.css";
import "../headers.css";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import image6 from "../img/breadcrumb-bg.png";
import Header from "../header.js";
import Footer from "../footer.js";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../owlcarousel/assets/owl.carousel.css";
import "../owlcarousel/assets//owl.theme.default.css";
import "react-calendar/dist/Calendar.css";
import { Toast } from "../Toast/Toast.jsx";
import { usePostAPIJson } from "../../Custom-Hook/usePostAPIJson.jsx";
import CustomModal from "../../Modal/CustomModal.jsx";
import { useHistory } from "react-router-dom/cjs/react-router-dom.js";
import MetaTags from "../MetaTags/MetaTags.jsx";
// let baseUrl = process.env.REACT_APP_IN_USE_URL;
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;
let baseUrlImg = process.env.REACT_APP_IN_USE_LIVE_URL_IMG;

function SingleServiceDetailPage() {
  const location = useLocation();
  const history = useHistory();
  const [isModalVisible, setModalVisible] = useState(false);
  const [serviceName, setServiceName] = useState();
  const hideModal = () => setModalVisible(false);

  const showModal = (serviceName) => {
    setServiceName(serviceName);
    setModalVisible(true);
  };
  const { id } = useParams();

  const specialityname = id
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  // window.location.reload();

  const [toasts, setToasts] = useState([]);

  const addToast = (message, color) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { id: Date.now(), message, color },
    ]);
  };

  const handleToastClose = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  const currentURL = window.location.href;

  const { serviceDetailPageData } = location.state || {};
  // console.log(serviceDetailPageData, "serviceDetailPageData");

  let getServiceByURL = `${baseUrl}/getServices-by-url`;
  const { data, postData: getServices } = usePostAPIJson(getServiceByURL);
  useEffect(() => {
    getServices({ url: id });
  }, []);
  useEffect(() => {
    if (data && !data.data) {
      history.push("/not-found");
    }
  }, [data]);
  return (
    <>
      <div>
        <div>
          <MetaTags
            title={data?.data?.metaTitle}
            metaDescription={data?.data?.metaDescription}
            metaKeyword={data?.data?.metaKeyword}
            currentURL={currentURL ? currentURL : null}
          ></MetaTags>
        </div>
      </div>
      <Header />

      <div
        class="page-bg-title"
        style={{ "background-image": `url(${image6})` }}
      >
        <div class="container">
          <h1>Our Services</h1>
        </div>
      </div>

      <div class="b1">
        <div class="container">
          <div aria-label="breadcrumb">
            <nav
              style={{
                "--bs-breadcrumb-divider":
                  "url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);",
              }}
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item">
                  <Link onClick={() => window.history.back()}>
                    Our Services
                  </Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {specialityname}
                </li>
              </ol>
            </nav>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-8 col-12">
                <p className="a2">{data?.data?.Description ?? ""}</p>
              </div>
              <div className="col-lg-3 col-md-4 col-12">
                <img
                  src={`${baseUrlImg}/public/${data?.data?.Image}`}
                  alt="service name"
                  loading="lazy"
                  width="100%"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "https://placehold.co/100x100";
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg1">
        <div class="container">
          <button
            class="btn btn-secondary btn-special-cta"
            onClick={() => {
              showModal(data?.data?.Name);
            }}
          >
            Book Now &#8594;
          </button>
        </div>
        <div className="container mt-5">
          <div className="toast-container position-fixed bottom-0 end-0 p-3">
            {toasts.map((toast) => (
              <Toast
                key={toast.id}
                message={toast.message}
                color={toast.color}
                onClose={() => handleToastClose(toast.id)}
              />
            ))}
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={isModalVisible}
        onRequestClose={hideModal}
        page={"serviceBook"}
        serviceName={serviceName}
        addToast={addToast}
      />
      <Footer />

      <script src="js/bootstrap.bundle.min.js"></script>
    </>
  );
}

export default SingleServiceDetailPage;
