import React, { useState, useEffect } from "react";
import "../css/bootstrap.css";
import "../headers.css";
import image2 from "../img/breadcrumb-bg.png";
import Header from "../header.js";
import Footer from "../footer.js";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import moment from "moment";
import { usePostAPIJson } from "../../Custom-Hook/usePostAPIJson.jsx";
import MetaTags from "../MetaTags/MetaTags.jsx";
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;
function NewsDetail() {
  const { newsurl } = useParams();
  let newsUrl = `${baseUrl}/get-news-by-url`;
  const { data: newsDetail, postData: getNewsByUrl } = usePostAPIJson(newsUrl);

  const currentURL = window.location.href;

  useEffect(() => {
    window.scrollTo(0, 0);
    getNewsByUrl({ url: newsurl });
  }, []);
  const originalDate = moment(newsDetail?.data?.createdAt);
  const formattedDate = originalDate.format("MMMM D, YYYY [at] HH:mm:ss");

  return (
    <>
      <div>
        <MetaTags
          title={newsDetail?.data?.metaTitle ?? " "}
          metaDescription={newsDetail?.data?.metaDescription ?? " "}
          metaKeyword={newsDetail?.data?.metaKeyword ?? " "}
          currentURL={currentURL ? currentURL : null}
        ></MetaTags>
      </div>
      <Header />

      <div
        class="page-bg-title"
        style={{ "background-image": `url(${image2})` }}
      >
        <div class="container">
          <h1>Latest News</h1>
        </div>
      </div>

      <div class="b1">
        <div class="container">
          <div aria-label="breadcrumb">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item">
                  <Link to="/news">Latest News</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {newsDetail?.data?.title ?? ""}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div class="bg1">
        <div class="container">
          <div class="row">
            <div class="col-md-9">
              <div class="blogs_card_main">
                <div class="blog_intro_container">
                  <div class="blog_intro_title">
                    {newsDetail?.data?.title ?? ""}
                    <small>{formattedDate ?? ""}</small>
                  </div>
                  <img
                    src={newsDetail?.data?.imageBanner ?? ""}
                    width="50%"
                    loading="lazy"
                    alt="Blog Title"
                  />
                  <p class="blog_intro_content">
                    {parse(newsDetail?.data?.description ?? "")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <script src="js/bootstrap.bundle.min.js"></script>
    </>
  );
}

export default NewsDetail;
