import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/bootstrap.css";
import "../headers.css";
import image1 from "../img/breadcrumb-bg.png";

import Header from "../header.js";
import Footer from "../footer.js";

import { useHistory, Link } from "react-router-dom";
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;
let baseUrlImg = process.env.REACT_APP_IN_USE_LIVE_URL_IMG;

function Specialitylist() {
  const [api, setApi] = useState([]);
  const [Api, setApi1] = useState();
  const history = useHistory();

  const redirecttospecialitydetail = (item) => {
    let give = decodeURIComponent(item.Name);
    const formattedUrl = give.replace(/\s+/g, "-").toLowerCase();
    history.push({
      pathname: `/specialitydetail/${item.url}`,
      state: { item },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    callApi();
    // callApi1();
  }, []);

  const callApi1 = () => {
    axios.post(`${baseUrl}/getHomepage/`).then((res) => {
      if (res.data.success == "1") {
        setApi1(res.data.data);
      }
      
    });
  };

  const callApi = () => {
    axios.post(`${baseUrl}/getSpecialityByUser/`).then((res) => {
      setApi(res.data.data);
     
    });
  };

  let tb_data = api.map((item, index) => {
    return (
      <div class="col-sm-6 col-lg-4" key={index}>
        <a
          onClick={redirecttospecialitydetail.bind(this, item)}
          class="speciality_tab2"
          style={{
            "background-image": `url(${`${baseUrlImg}/public/${item.Image}`})`,
          }}
        >
          <span>{item.Name}</span>
        </a>
      </div>
    );
  });


 
    
  return (
    <>
      <Header />

      <div
        class="page-bg-title"
        style={{ "background-image": `url(${image1})` }}
      >
        <div class="container">
          <h1>Our Specialities</h1>
        </div>
      </div>

      <div class="b1">
        <div class="container">
          <div aria-label="breadcrumb">
            <nav
              style={{
                "--bs-breadcrumb-divider":
                  "url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;)",
              }}
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Our Specialities
                </li>
              </ol>
            </nav>
          </div>
          <div class="col-lg-9 col-md-10">
            <p class="a2">{Api ? Api.specilaityDescription : ""}</p>
          </div>
        </div>
      </div>

      <div class="bg1">
        <div class="container">
          <div class="row">{tb_data}</div>
        </div>
      </div>

      {/* <div class="bg1">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <a href="#" class="home-feature">
                <img
                  src={image2}
                  width="30"
                  height="30"
                  loading="lazy"
                  alt="Book Appointment"
                />{" "}
                Book Appointment
              </a>
            </div>
            <div class="col-md-4">
              <a href="#" class="home-feature">
                <img
                  src={image3}
                  width="30"
                  height="30"
                  loading="lazy"
                  alt="Book Appointment"
                />{" "}
                Health Checkup
              </a>
            </div>
            <div class="col-md-4">
              <Link to="/serviceList" class="home-feature">
                <img
                  src={image4}
                  width="30"
                  height="30"
                  loading="lazy"
                  alt="Book Appointment"
                />{" "}
                Essential Services
              </Link>
            </div>
          </div>
        </div>
      </div> */}

      <Footer />
    </>
  );
}

export default Specialitylist;
