import Header from "./header.js";
import "./css/bootstrap.css";
import "./headers.css";
import Footer from "./footer.js";
import image1 from "./img/breadcrumb-bg.png";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, Link } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import MetaTags from "./MetaTags/MetaTags.jsx";
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;

function Jobdetail() {
  let locations = useLocation();
  const [fileSizeError, setFileSizeError] = useState("");

  const [fullname, setfullname] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [location, setLocation] = useState("");
  const [fileTypeError, setFileTypeError] = useState("");
  const [file, setFile] = useState("");
  const allowedFileTypes = ["application/pdf", "application/msword"];

  function handleChange(e) {
    const file = e.target.files[0];
    if (file) {
      const maxSize = 2 * 1024 * 1024; // 2 MB

      if (file.size > maxSize) {
        setFileSizeError("File size exceeds the maximum allowed limit (1 MB).");
      } else {
        if (file) {
          if (!allowedFileTypes.includes(file.type)) {
            setFileTypeError(
              "Invalid file form, you can upload attachment in (pdf, docx) only."
            );
          } else {
            setFileTypeError("");
            setFileSizeError("");
            setFile(file);
          }
        }
      }
    }
  }

  const uploadjob = (e) => {
    e.preventDefault();

    var bodyFormData = new FormData();
    bodyFormData.append("fullName", fullname);
    bodyFormData.append("email", email);
    bodyFormData.append("contactNo", mobile);
    bodyFormData.append("currentLocation", location);
    bodyFormData.append("resume", file);
    bodyFormData.append("role", locations.state.item.Title);
    axios
      .post(`${baseUrl}/addJob`, bodyFormData)
      .then((res) => {
        if (res.data.success == 1) {
          setfullname("");
          setLocation("");
          setFile("");
          setemail("");
          setmobile("");
          alert("job submitted successsfully");
        } else if (res.data.success === 0) {
          alert("something went wrong");
        }
      })
      .catch((err) => {
        alert(err);
      });
  };
  const currentURL = window.location.href;
  return (
    <>
      <div>
        <MetaTags
          title={locations ? locations.state.item.metaTitle : " "}
          metaDescription={
            locations ? locations.state.item.metaDescription : " "
          }
          metaKeyword={locations ? locations.state.item.metaKeyword : " "}
          currentURL={currentURL ? currentURL : null}
        ></MetaTags>
      </div>
      <Header />
      <div
        class="page-bg-title"
        style={{ "background-image": `url(${image1})` }}
      >
        <div class="container">
          <h1>Careers</h1>
        </div>
      </div>
      <div class="bg1">
        <div class="container">
          <div aria-label="breadcrumb">
            <nav
              style={{
                "--bs-breadcrumb-divider":
                  "url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);",
              }}
              aria-label="breadcrumb"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <Link to="/career">Careers</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {locations.state.item.Title}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-8">
            <div class="job-detail">
              {parse(locations.state.item.Description)}
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4">
            <div class="job-form">
              <h2>Submit Your Application</h2>
              <form class="row g-3">
                <div class="form-floating col-12">
                  <input
                    type="text"
                    class="form-control"
                    id="inputFullname"
                    placeholder="Name"
                    value={fullname}
                    onChange={(e) => {
                      setfullname(e.target.value);
                    }}
                  />
                  <label for="inputFullname">Full Name</label>
                </div>
                <div class="form-floating col-12">
                  <input
                    type="email"
                    class="form-control"
                    id="inputEmail"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                  />
                  <label for="inputEmail">Email</label>
                </div>
                <div class="form-floating col-12">
                  <input
                    type="text"
                    class="form-control"
                    id="inputContactnumber"
                    placeholder="Contact Number"
                    value={mobile}
                    onChange={(e) => {
                      setmobile(e.target.value);
                    }}
                  />
                  <label for="inputContactnumber">Contact Number</label>
                </div>
                <div class="form-floating col-12">
                  <input
                    type="text"
                    class="form-control"
                    id="currentLocation"
                    placeholder="Current Location"
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                  />
                  <label for="currentLocation">Current Location</label>
                </div>
                <div class="form-floating col-12">
                  <input
                    class="form-control"
                    type="file"
                    id="formFile"
                    accept=".pdf, .doc, .docx"
                    onChange={handleChange}
                  />
                  <label for="formFile">
                    Upload your resume (accepted only pdf, docx)
                  </label>
                  {fileSizeError ||
                    (fileTypeError && (
                      <p style={{ color: "red" }}>
                        {fileSizeError || fileTypeError}
                      </p>
                    ))}
                </div>
                <div class="col-12">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    onClick={uploadjob}
                  >
                    Apply
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default Jobdetail;
