import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import image2 from "../img/breadcrumb-bg.png";
import Header from "../header.js";
import Footer from "../footer.js";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import MetaTags from "../MetaTags/MetaTags.jsx";
let baseUrlImg = process.env.REACT_APP_IN_USE_LIVE_URL_IMG;

function Bloglist() {
  const [blog, setBlog] = useState([]);
  const [page, setPage] = useState("");
  const [visibleBlogs, setVisibleBlogs] = useState(9);
  const loadMoreAmount = 9;
  let baseurl = process.env.REACT_APP_IN_USE_LIVE_URL;
  const loadMore = () => {
    setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + loadMoreAmount);
  };

  const history = useHistory();

  const redirecttonewsdetail = (item) => {
    let give = decodeURIComponent(item.url);
    const formattedUrl = give.replace(/\s+/g, "-").toLowerCase();
    history.push({
      pathname: `/blogdetail/${item.url}`,
      state: { item },
    });
  };
  const getBlog = async () => {
    try {
      const res = await fetch(`${baseurl}/getblogs-Main`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({ token }),
      });

      if (res.ok) {
        const responseData = await res.json();

        setBlog(responseData.blogs);
        // Handle the response data as needed
      } else {
      }
    } catch (e) {
      alert(e);
    }
  };

  const currentURL = window.location.href;

  // getBloglistpage
  useEffect(() => {
    window.scrollTo(0, 0);
    getBlog();
    //   getBlogListPage();
  }, []);

  let tbdaat = blog.map((item, index) => {
    return (
      <div class="col-md-4 col-sm-6" key={index}>
        <div class="blogs_card">
          <div
            class="blog_banner"
            style={{ background: `url(${item.imageThumbnail})` }}
          ></div>
          <div class="blog_intro_container">
            <div class="blog_intro_title">{item.title}</div>
            <p class="blog_intro_content">{item.body}</p>
            <button onClick={redirecttonewsdetail.bind(this, item)}>
              Read More{" "}
            </button>
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      <div>
        <MetaTags
          title={blog ? blog.metaTitle : " "}
          metaDescription={blog ? blog.metaDescription : " "}
          metaKeyword={blog ? blog.metaKeyword : " "}
          currentURL={currentURL ?? ""}
        ></MetaTags>
      </div>
      <Header />

      <div
        class="page-bg-title"
        style={{ "background-image": `url(${image2})` }}
      >
        <div class="container">
          <h1>Blogs</h1>
        </div>
      </div>

      <div class="b1">
        <div class="container">
          <div aria-label="breadcrumb">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Blogs
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div class="bg1">
        <div class="container">
          <div class="row">{tbdaat}</div>
        </div>
      </div>

      <Footer />

      <script src="js/bootstrap.bundle.min.js"></script>
    </>
  );
}
export default Bloglist;
